import axios from "axios";
import { useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";

const source = axios.CancelToken.source();

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
});

export const useWorkWithData = () => {
  const { instance, accounts } = useMsal();

  const getAccessToken = useCallback(async () => {
    if (accounts.length === 0) {
      console.log("User is not signed in");
    }
    const request = {
      scopes: [process.env.REACT_APP_BACKEND_SCOPE],
      account: accounts[0],
    };
    const authResult = await instance.acquireTokenSilent(request);
    return authResult.accessToken;
  }, [accounts, instance]);

  const getElements = useCallback(
    async (params) => {
      try {
        const accessToken = await getAccessToken();
        let response = await api.get("/api/Equipment/GetElements", {
          params,
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
          cancelToken: source.token,
        });
        return response.data;
      } catch (error) {
        toast.error("Table data fetch failed");
        console.error(error);
      }
    },
    [getAccessToken]
  );

  const getGeneralJDECharacterictics = useCallback(
    async (equipmentTagId) => {
      try {
        const accessToken = await getAccessToken();
        let response = await api.get(
          "/api/Characteristics/GetJdeEquipmentTagCharacteristics",
          {
            params: { equipmentTagId },
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${accessToken}`,
            },
            cancelToken: source.token,
          }
        );
        return response.data;
      } catch (error) {
        toast.error("General JDE characteristics fetch failed");
        console.error(error);
      }
    },
    [getAccessToken]
  );

  const getEquipmentGeneralStatusDetails = useCallback(
    async (equipmentTagId) => {
      try {
        const accessToken = await getAccessToken();
        let response = await api.get(
          "/api/Characteristics/GetEquipmentGeneralStatusDetails",
          {
            params: { equipmentTagId },
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${accessToken}`,
            },
            cancelToken: source.token,
          }
        );
        return response.data;
      } catch (error) {
        toast.error("Equipment status or comment fetch failed");
        console.error(error);
      }
    },
    [getAccessToken]
  );

  const getMDSCharacterictics = useCallback(
    async (equipmentTagId, isGeneralChar) => {
      try {
        const accessToken = await getAccessToken();
        let response = await api.get(
          "/api/Characteristics/GetMdsEquipmentTagCharacteristics",
          {
            params: { equipmentTagId, isGeneralChar },
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${accessToken}`,
            },
            cancelToken: source.token,
          }
        );
        return response.data;
      } catch (error) {
        toast.error("General MDS characteristics fetch failed");
        console.error(error);
      }
    },
    [getAccessToken]
  );

  const getPlannerGroupOptions = useCallback(async () => {
    try {
      const accessToken = await getAccessToken();
      let response = await api.get(
        "/api/Characteristics/GetPlannerGroupOptions",
        {
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
          cancelToken: source.token,
        }
      );
      return response.data;
    } catch (error) {
      toast.error("Planner options fetch failed");
      console.error(error);
    }
  }, [getAccessToken]);

  const getCostCenterOptions = useCallback(async () => {
    try {
      const accessToken = await getAccessToken();
      let response = await api.get(
        "/api/Characteristics/GetCostCenterOptions",
        {
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
          cancelToken: source.token,
        }
      );
      return response.data;
    } catch (error) {
      toast.error("Cost center options fetch failed");
      console.error(error);
    }
  }, [getAccessToken]);

  const getManufacturerOptions = useCallback(async () => {
    try {
      const accessToken = await getAccessToken();
      let response = await api.get(
        "/api/Characteristics/GetAllEquipmentManufacturers",
        {
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
          cancelToken: source.token,
        }
      );
      return response.data;
    } catch (error) {
      toast.error("Manufacturer options fetch failed");
      console.error(error);
    }
  }, [getAccessToken]);

  const getEquipmentStatuses = useCallback(async () => {
    try {
      const accessToken = await getAccessToken();
      let response = await api.get(
        "/api/Characteristics/GetCharacteristicStatus",
        {
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
          cancelToken: source.token,
        }
      );
      return response.data;
    } catch (error) {
      toast.error("Equipment statuses fetch failed");
      console.error(error);
    }
  }, [getAccessToken]);

  const getClassSpecificEquipments = useCallback(
    async (equipmentTagId) => {
      try {
        const accessToken = await getAccessToken();
        let response = await api.get(
          "/api/Characteristics/GetGeneralEquipmentTagId",
          {
            params: { equipmentTagId },
            headers: {
              accept: "*/*",
              Authorization: `Bearer ${accessToken}`,
            },
            cancelToken: source.token,
          }
        );
        return response.data;
      } catch (error) {
        toast.error("Class specific characteristics fetch failed");
        console.error(error);
      }
    },
    [getAccessToken]
  );

  const getWalkdownPictures = useCallback(
    async (equipmentTags) => {
      try {
        const accessToken = await getAccessToken();
        let response = await api.get("/api/Equipment/GetEquipmentImage", {
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
            equipmentTags,
          },
          cancelToken: source.token,
        });
        return response.data;
      } catch (error) {
        toast.error("Walkdown pictures fetch failed");
        console.error(error);
      }
    },
    [getAccessToken]
  );

  const getAreaAndUnitCodes = useCallback(async () => {
    try {
      const accessToken = await getAccessToken();
      let response = await api.get("/api/Characteristics/GetAreaCode", {
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${accessToken}`,
        },
        cancelToken: source.token,
      });
      return response.data;
    } catch (error) {
      toast.error("Area and unit codes fetch failed");
      console.error(error);
    }
  }, [getAccessToken]);

  const getCategoriesList = useCallback(async () => {
    try {
      const accessToken = await getAccessToken();
      let response = await api.get("/api/Categories/GetCategoriesList", {
        headers: {
          accept: "*/*",
          Authorization: `Bearer ${accessToken}`,
        },
        cancelToken: source.token,
      });
      return response.data;
    } catch (error) {
      toast.error("Categories fetch failed");
      console.error(error);
    }
  }, [getAccessToken]);

  const getElementsByColumn = useCallback(
    async (columnName) => {
      try {
        const accessToken = await getAccessToken();
        let response = await api.get("/api/Equipment/GetAllDataByColumn", {
          params: { columnName },
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
          },
          cancelToken: source.token,
        });
        return response.data;
      } catch (error) {
        toast.error("Column results fetch failed");
        console.error(error);
      }
    },
    [getAccessToken]
  );

  const addNewManufacturer = useCallback(
    async (bodyParams) => {
      const toastId = toast.loading("Manufacturer is adding...");
      try {
        const accessToken = await getAccessToken();
        let response = await api.post(
          "/api/Characteristics/AddNewEquipmentManufacturer",
          bodyParams,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            cancelToken: source.token,
          }
        );
        toast.update(toastId, {
          render: "Manufacturer added successfully",
          type: "success",
          isLoading: false,
          autoClose: 4000,
        });
        return response.data;
      } catch (error) {
        toast.update(toastId, {
          render: "Something went wrong or manufacturer already exists",
          type: "error",
          isLoading: false,
          autoClose: 4000,
        });
        console.error(error);
      }
    },
    [getAccessToken]
  );

  const updateJdeEquipmentTagCharacteristics = useCallback(
    async (bodyParams) => {
      let toastId;
      try {
        const accessToken = await getAccessToken();
        toastId = toast.loading("Characteristics are updating...");
        let response = await api.post(
          "/api/Characteristics/UpdateJdeEquipmentTagCharacteristics",
          bodyParams,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            cancelToken: source.token,
          }
        );

        if (response) {
          if (response.status === 200) {
            toast.update(toastId, {
              render: "Characteristics updated succesfully",
              type: "success",
              isLoading: false,
              autoClose: 5000,
            });
            return response;
          }
        }
      } catch (error) {
        toast.update(toastId, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        console.error(error);
      }
    },
    [getAccessToken]
  );

  const updateEquipmentGeneralStatusDetails = useCallback(
    async (bodyParams) => {
      let toastId;
      try {
        const accessToken = await getAccessToken();
        toastId = toast.loading("Equipment data is updating...");
        let response = await api.post(
          "/api/Characteristics/UpdateEquipmentGeneralStatusDetails",
          bodyParams,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            cancelToken: source.token,
          }
        );

        if (response) {
          if (response.status === 200) {
            toast.update(toastId, {
              render: "Equipment data updated succesfully",
              type: "success",
              isLoading: false,
              autoClose: 5000,
            });
            return response;
          }
        }
      } catch (error) {
        toast.update(toastId, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        console.error(error);
      }
    },
    [getAccessToken]
  );

  const updateMdsEquipmentTagCharacteristics = useCallback(
    async (bodyParams) => {
      let toastId;
      try {
        const accessToken = await getAccessToken();
        toastId = toast.loading("Characteristics are updating...");
        let response = await api.post(
          "/api/Characteristics/UpdateMdsEquipmentTagCharacteristics",
          bodyParams,
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            cancelToken: source.token,
          }
        );

        if (response) {
          if (response.status === 200) {
            toast.update(toastId, {
              render: "Characteristics updated succesfully",
              type: "success",
              isLoading: false,
              autoClose: 5000,
            });
            return response;
          }
        }
      } catch (error) {
        toast.update(toastId, {
          render: "Something went wrong",
          type: "error",
          isLoading: false,
          autoClose: 5000,
        });
        console.error(error);
      }
    },
    [getAccessToken]
  );

  const deleteWalkdownPictures = useCallback(
    async (equipmentImageIds) => {
      const imageCount = equipmentImageIds.length;
      const toastId = toast.loading(
        `${imageCount === 1 ? "Image is" : "Images are"} deleting...`
      );
      try {
        const accessToken = await getAccessToken();
        let response = await api.delete("/api/Equipment/DeleteEquipmentImage", {
          headers: {
            accept: "*/*",
            Authorization: `Bearer ${accessToken}`,
            equipmentImageIds,
          },
          cancelToken: source.token,
        });
        toast.update(toastId, {
          render: `${
            imageCount === 1 ? "Image is " : "Images are"
          } deleted successfully`,
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
        return response.data;
      } catch (error) {
        toast.update(toastId, {
          render: "Something went wrong when image deletion",
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        console.error(error);
      }
    },
    [getAccessToken]
  );

  return {
    getElements,
    getCategoriesList,
    addNewManufacturer,
    getAreaAndUnitCodes,
    deleteWalkdownPictures,
    getElementsByColumn,
    getWalkdownPictures,
    getEquipmentStatuses,
    getGeneralJDECharacterictics,
    getMDSCharacterictics,
    getCostCenterOptions,
    getPlannerGroupOptions,
    getManufacturerOptions,
    getClassSpecificEquipments,
    getEquipmentGeneralStatusDetails,
    updateJdeEquipmentTagCharacteristics,
    updateMdsEquipmentTagCharacteristics,
    updateEquipmentGeneralStatusDetails,
  };
};
