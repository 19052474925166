export const TABLE_COLUMNS = {
  DEFAULT_USER: [
    {
      id: "asseT_NUMBER_BK",
      label: "Asset number",
      width: 150,
      isVisible: true,
      isChecked: false,
      isDisabled: false,
      align: "left",
      order: 1,
    },
    {
      id: "equipmentAndParentTag",
      label: "Equipment & parent tag",
      width: 180,
      isVisible: true,
      isChecked: true,
      isDisabled: true,
      align: "left",
      order: 2,
    },
    {
      id: "equipmenT_TAG_DESCRIPTION",
      label: "Description",
      width: 180,
      isVisible: true,
      isChecked: true,
      isDisabled: true,
      align: "left",
      order: 3,
    },
    {
      id: "aC_RANKING",
      label: "Criticality",
      width: 140,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 4,
    },
    {
      id: "assigN_TO",
      label: "Assigned to",
      width: 140,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 5,
    },
    {
      id: "iC_RANKING",
      label: "IC ranking",
      width: 100,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 6,
    },
    {
      id: "manufacturerAndSerialNumber",
      label: "Manufacturer & serial number",
      width: 180,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 7,
    },
    {
      id: "equipmenT_MODEL_NUMBER",
      label: "Model",
      width: 200,
      isVisible: true,
      isChecked: false,
      isDisabled: false,
      align: "left",
      order: 8,
    },
    {
      id: "pid",
      label: "PID",
      width: 160,
      isVisible: true,
      isChecked: false,
      isDisabled: false,
      align: "left",
      order: 9,
    },
    {
      id: "jdE_STATUS",
      label: "JDE status",
      width: 100,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 10,
    },
    {
      id: "equipmenT_STATUS",
      label: "Equipment status",
      width: 150,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 11,
    },
    {
      id: "comment",
      label: "Comment",
      width: 200,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 12,
    },
    {
      id: "syS_CODE",
      label: "System group",
      width: 100,
      isVisible: true,
      isChecked: false,
      isDisabled: false,
      align: "left",
      order: 13,
    },
    {
      id: "planT_SECTION",
      label: "Plant section",
      width: 100,
      isVisible: true,
      isChecked: false,
      isDisabled: false,
      align: "left",
      order: 14,
    },
    {
      id: "planT_FACILITY",
      label: "Plant/facility",
      width: 100,
      isVisible: true,
      isChecked: false,
      isDisabled: false,
      align: "left",
      order: 15,
    },
    {
      id: "details",
      label: "Details",
      width: 100,
      isVisible: false,
      isChecked: true,
      isDisabled: true,
      align: "center",
      order: 16,
    },
  ],
  SME_USER: [
    {
      id: "asseT_NUMBER_BK",
      label: "Asset number",
      width: 150,
      isVisible: true,
      isChecked: false,
      isDisabled: false,
      align: "left",
      order: 1,
    },
    {
      id: "equipmentAndParentTag",
      label: "Equipment & parent tag",
      width: 180,
      isVisible: true,
      isChecked: true,
      isDisabled: true,
      align: "left",
      order: 2,
    },
    {
      id: "equipmenT_TAG_DESCRIPTION",
      label: "Description",
      width: 180,
      isVisible: true,
      isChecked: true,
      isDisabled: true,
      align: "left",
      order: 3,
    },
    {
      id: "aC_RANKING",
      label: "Criticality",
      width: 140,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 4,
    },
    {
      id: "assigN_TO",
      label: "Assigned to",
      width: 140,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 5,
    },
    {
      id: "iC_RANKING",
      label: "IC ranking",
      width: 100,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 6,
    },
    {
      id: "manufacturerAndSerialNumber",
      label: "Manufacturer & serial number",
      width: 180,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 7,
    },
    {
      id: "equipmenT_MODEL_NUMBER",
      label: "Model",
      width: 200,
      isVisible: true,
      isChecked: false,
      isDisabled: false,
      align: "left",
      order: 8,
    },
    {
      id: "pid",
      label: "PID",
      width: 160,
      isVisible: true,
      isChecked: false,
      isDisabled: false,
      align: "left",
      order: 9,
    },
    {
      id: "jdE_STATUS",
      label: "JDE status",
      width: 100,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 10,
    },
    {
      id: "equipmenT_STATUS",
      label: "Equipment status",
      width: 150,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 11,
    },
    {
      id: "comment",
      label: "Comment",
      width: 200,
      isVisible: true,
      isChecked: true,
      isDisabled: false,
      align: "left",
      order: 12,
    },
    {
      id: "syS_CODE",
      label: "System group",
      width: 100,
      isVisible: true,
      isChecked: false,
      isDisabled: false,
      align: "left",
      order: 13,
    },
    {
      id: "planT_SECTION",
      label: "Plant section",
      width: 100,
      isVisible: true,
      isChecked: false,
      isDisabled: false,
      align: "left",
      order: 14,
    },
    {
      id: "planT_FACILITY",
      label: "Plant/facility",
      width: 100,
      isVisible: true,
      isChecked: false,
      isDisabled: false,
      align: "left",
      order: 15,
    },
    {
      id: "details",
      label: "Details",
      width: 100,
      isVisible: false,
      isChecked: true,
      isDisabled: true,
      align: "center",
      order: 16,
    },
  ],
};

export const DEFAULT_WWD_COLUMNS = TABLE_COLUMNS.DEFAULT_USER.toSorted(
  (a, b) => a.order - b.order
);

export const SME_USER_WWD_COLUMNS = TABLE_COLUMNS.SME_USER.toSorted(
  (a, b) => a.order - b.order
);

export const TABLE_COLUMNS_IN_FILTER = [
  {
    id: "equipmenT_TAG",
    value: "equipmentAndParentTag",
    label: "Equipment Tag",
  },
  {
    id: "parenT_EQUIPMENT_TAG",
    value: "equipmentAndParentTag",
    label: "Parent Tag",
  },
  {
    id: "equipmenT_TAG_DESCRIPTION",
    value: "equipmenT_TAG_DESCRIPTION",
    label: "Description",
  },
  {
    id: "manufacturer",
    value: "manufacturerAndSerialNumber",
    label: "Manufacturer",
  },
  {
    id: "seriaL_NUMBER",
    value: "manufacturerAndSerialNumber",
    label: "Serial number",
  },
  {
    id: "asseT_NUMBER_BK",
    value: "asseT_NUMBER_BK",
    label: "Asset number",
  },
  {
    id: "equipmenT_MODEL_NUMBER",
    value: "equipmenT_MODEL_NUMBER",
    label: "Model",
  },
  {
    id: "pid",
    value: "pid",
    label: "PID",
  },
  {
    id: "aC_RANKING",
    value: "aC_RANKING",
    label: "Criticality",
  },
  {
    id: "assigN_TO",
    value: "assigN_TO",
    label: "Assigned to",
  },
  {
    id: "syS_CODE",
    value: "syS_CODE",
    label: "System group",
  },
  {
    id: "planT_SECTION",
    value: "planT_SECTION",
    label: "Plant section",
  },
  {
    id: "jdE_STATUS",
    value: "jdE_STATUS",
    label: "JDE status",
  },
  {
    id: "planT_FACILITY",
    value: "planT_FACILITY",
    label: "Plant/Facility",
  },
  {
    id: "iC_RANKING",
    value: "iC_RANKING",
    label: "IC ranking",
  },
  {
    id: "equipmenT_STATUS",
    value: "equipmenT_STATUS",
    label: "Equipment status",
  },
  {
    id: "comment",
    value: "comment",
    label: "Comment",
  },
];

export const EQUIPMENT_GENERAL_CHAR_NAME = {
  AC_RANKING: "AC_RANKING",
  IC_RANKING: "IC_RANKING",
  LOCATION: "LOCATION",
  PLANT_SECTION: "PLANT_SECTION",
  MAN_SERIAL_NUMBER: "MAN_SERIAL_NUMBER",
  MANUFACTURER: "MANUFACTURER",
  CLASS: "CLASS",
  STATUS: "STATUS",
  EQUIPMENT_DESCRIPTION: "EQUIPMENT_DESCRIPTION",
  PID: "PID",
  SERVICE_DETAIL: "SERVICE_DETAIL",
  MODELNO: "MODELNO",
  SAP_EQUIPMENT_ID: "SAP_EQUIPMENT_ID",
  CRITICALITY: "CRITICALITY",
  SYS_CODE: "SYS_CODE",
};

export const FILTER_PARAMS = {
  CATEGORY_ID: "CategoryId",
  CLASS_ID: "ClassId",
  SUBCLASS_ID: "SubClassId",
  AREA_CODE: "AreaCode",
  UNIT_CODE: "UnitCode",
  FILTERED_COLUMS: "FilteredColums",
  SEARCH: "Search",
};

export const TABLE_COLUMN_LABELS = {
  equipmenT_TAG: "Equipment tag",
  manufacturer: "Manufacturer",
  seriaL_NUMBER: "Serial number",
  asseT_NUMBER_BK: "Asset number",
  equipmenT_MODEL_NUMBER: "Model",
  pid: "PID",
  parenT_EQUIPMENT_TAG: "Parent tag",
  equipmenT_TAG_DESCRIPTION: "Description",
  aC_RANKING: "Criticality",
  syS_CODE: "System group",
  planT_SECTION: "Plant section",
  jdE_STATUS: "JDE status",
  planT_FACILITY: "Plant/Facility",
  constructioN_YEAR_MONTH: "Construction year month",
  iC_RANKING: "IC ranking",
  equipmenT_STATUS: "Equipment status",
  comment: "Comment",
  details: "Details",
  assigN_TO: "Assigned to",
};

export const CRITICALITY_OPTIONS = [
  {
    label: "Low",
    value: "Low",
  },
  {
    label: "Normal",
    value: "Normal",
  },
  {
    label: "Critical",
    value: "Critical",
  },
  {
    label: "Not applicable",
    value: "Not applicable",
  },
];

export const GENERAL_STATUS_OPTIONS = [
  {
    value: "DN",
    label: "DN",
  },
  {
    value: "DM",
    label: "DM",
  },
  {
    value: "AV",
    label: "AV",
  },
];

export const MANUFACTURER_STATUS = {
  NEW: "New",
  JDE_E1_ORIGINAL: "JDE E1 original",
};

export const OPERATOR = [
  {
    value: "=",
    label: "Equals",
  },
  {
    value: "%",
    label: "Contains",
  },
  {
    value: "%S",
    label: "Starts With",
  },

  {
    value: "%E",
    label: "Ends With",
  },
  {
    value: "%E",
    label: "Greater than",
  },
  {
    value: "%E",
    label: "Less than",
  },
];

export const FILENAME = "Work with data";
