import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import EditIcon from "@mui/icons-material/Edit";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

import {
  Autocomplete,
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import {
  isUserActionsEnabled,
  selectUserRole,
} from "../../../store/slices/global/userSlice";
import {
  isCharacteristicsUpdated,
  isSpecificCharsUpdated,
  isWalkdownPicturesUpdated,
  selectedEquipmentTagId,
} from "../../../store/slices/work-with-data/characteristicsSlice";
import { useWorkWithData } from "../../../hooks/useWorkWithData";
import { getStatusesByUserRole } from "../../../utils";

import {
  GeneralJDECharacteristics,
  EquipmentDocuments,
  EquipmentWalkdownPictures,
} from ".";
// import { GeneralMDSCharacteristics } from "./GeneralMDSCharacteristics";
// import { ClassSpecificMDSCharacteristics } from "./ClassSpecificMDSCharacteristics";
import { EQUIPMENT_GENERAL_CHAR_NAME } from "../../../constants/work-with-data";

export const WorkWithDataDetails = ({ id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const role = useSelector(selectUserRole);
  const equipmentTagId = useSelector(selectedEquipmentTagId);
  const isGeneralEquipmentsUpdated = useSelector(isCharacteristicsUpdated);
  const isSpecificEquipmentsUpdated = useSelector(isSpecificCharsUpdated);
  const isEquipmentPicturesUpdated = useSelector(isWalkdownPicturesUpdated);
  const isUserActionEnabled = useSelector(isUserActionsEnabled);
  const [activeButton, setActiveButton] = useState("generalJDE");

  const [statusEditMode, setStatusEditMode] = useState(false);
  const [assetNumberPhk, setAssetNumberPhk] = useState("");
  const [generalJDEChars, setGeneralJDEChars] = useState([]);
  const [initialEquipmentStatus, setInitialEquipmentStatus] = useState({
    value: "",
    label: "",
  });
  const [generalEquipmentStatus, setGeneralEquipmentStatus] = useState({
    value: "",
    label: "",
  });

  const [initialEquipmentComment, setInitialEquipmentComment] = useState("");
  const [generalEquipmentComment, setGeneralEquimentComment] = useState("");
  const [assignedTo, setAssignedTo] = useState("");

  const [isGeneralJDECharsLoading, setIsGeneralJDECharsLoading] =
    useState(true);

  const [isEquipmentStatusLoading, setIsEquipmentStatusLoading] =
    useState(true);

  const { updateEquipmentGeneralStatusDetails } = useWorkWithData();

  // const [generalMDSChars, setGeneralMDSChars] = useState([]);
  // const [isGeneralMDSCharsLoading, setIsGeneralMDSCharsLoading] =
  //   useState(true);

  // const [specificMDSChars, setSpecificMDSChars] = useState([]);
  // const [isSpecificMDSCharsLoading, setIsSpecificMDSCharsLoading] =
  //   useState(true);

  const [costCenterOptions, setCostCenterOptions] = useState([]);
  const [equipmentStatuses, setEquipmentStatuses] = useState([]);

  const [imagesInfo, setImagesInfo] = useState(null);

  const [isImagesLoading, setIsImagesLoading] = useState(true);

  const {
    getGeneralJDECharacterictics,
    // getMDSCharacterictics,
    getWalkdownPictures,
    getCostCenterOptions,
    getEquipmentStatuses,
    getEquipmentGeneralStatusDetails,
  } = useWorkWithData();

  const fetchEquipmentStatusDetails = () => {
    getEquipmentGeneralStatusDetails(id)
      .then((response) => {
        if (response) {
          const generalStatus = {
            label: response[0].equipmentStatusName,
            value: response[0].equipmentStatusPhk,
          };
          setAssignedTo(response[0].assignTo);
          setGeneralEquipmentStatus(generalStatus);
          setInitialEquipmentStatus(generalStatus);
          setGeneralEquimentComment(response[0].equipmentComment ?? "");
          setInitialEquipmentComment(response[0].equipmentComment ?? "");
          setAssetNumberPhk(response[0].equipmentAssetNumberPhk);
          setIsEquipmentStatusLoading(false);
        }
      })
      .finally(() => {
        setIsEquipmentStatusLoading(false);
      });
  };

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      setIsGeneralJDECharsLoading(true);
      getGeneralJDECharacterictics(id)
        .then((response) => {
          if (isMounted && response) {
            const formattedResponse = response
              .sort((a, b) =>
                a.jdeCharacteristicDescription > b.jdeCharacteristicDescription
                  ? 1
                  : -1
              )
              .filter(
                (item) =>
                  EQUIPMENT_GENERAL_CHAR_NAME[item.jdeCharacteristicName]
              );
            setGeneralJDEChars(formattedResponse);
            setIsGeneralJDECharsLoading(false);
          }
        })
        .finally(() => {
          setIsGeneralJDECharsLoading(false);
        });
    };
    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [getGeneralJDECharacterictics, id]);

  useEffect(() => {
    fetchEquipmentStatusDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   let isMounted = true;
  //   const fetchRows = () => {
  //     setIsGeneralMDSCharsLoading(true);
  //     getMDSCharacterictics(id, true)
  //       .then((response) => {
  //         if (isMounted && response) {
  //           const sorted = response.sort((a, b) =>
  //             a.mdsCharacteristicDescription > b.mdsCharacteristicDescription
  //               ? 1
  //               : -1
  //           );
  //           setGeneralMDSChars(sorted);
  //           setIsGeneralMDSCharsLoading(false);
  //         }
  //       })
  //       .finally(() => {
  //         setIsGeneralMDSCharsLoading(false);
  //       });
  //   };

  //   fetchRows();
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [
  //   id,
  //   getMDSCharacterictics,
  //   dispatch,
  //   isGeneralEquipmentsUpdated,
  //   isSpecificEquipmentsUpdated,
  // ]);

  // useEffect(() => {
  //   let isMounted = true;
  //   const fetchRows = () => {
  //     setIsSpecificMDSCharsLoading(true);
  //     getMDSCharacterictics(id, false)
  //       .then((response) => {
  //         if (isMounted && response) {
  //           const sorted = response.sort((a, b) =>
  //             a.mdsCharacteristicDescription > b.mdsCharacteristicDescription
  //               ? 1
  //               : -1
  //           );
  //           setSpecificMDSChars(sorted);
  //           setIsSpecificMDSCharsLoading(false);
  //         }
  //       })
  //       .finally(() => {
  //         setIsSpecificMDSCharsLoading(false);
  //       });
  //   };

  //   fetchRows();
  //   return () => {
  //     isMounted = false;
  //   };
  // }, [
  //   id,
  //   getMDSCharacterictics,
  //   dispatch,
  //   isGeneralEquipmentsUpdated,
  //   isSpecificEquipmentsUpdated,
  // ]);

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      getEquipmentStatuses().then((response) => {
        if (isMounted && response) {
          const statuses = getStatusesByUserRole(role, response);
          const mappedStatuses = statuses
            .map((item) => ({
              label: item.characteristicStatusName,
              value: item.characteristicStatusPhk,
            }))
            .filter((item) => item.label);
          setEquipmentStatuses(mappedStatuses);
        }
      });
    };

    fetchRows();

    return () => {
      isMounted = false;
    };
  }, [getEquipmentStatuses, role]);

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      getWalkdownPictures([equipmentTagId])
        .then((response) => {
          if (isMounted && response) {
            setImagesInfo({
              images: response?.[0]?.equipmentImageInfo,
              user: response?.[0]?.user,
            });
          }
        })
        .finally(() => {
          setIsImagesLoading(false);
        });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [equipmentTagId, getWalkdownPictures, isEquipmentPicturesUpdated]);

  useEffect(() => {
    let isMounted = true;
    const fetchRows = () => {
      getCostCenterOptions().then((response) => {
        if (isMounted && response) {
          setCostCenterOptions(response);
        }
      });
    };

    fetchRows();
    return () => {
      isMounted = false;
    };
  }, [getCostCenterOptions]);

  const handleClick = (btn) => {
    setActiveButton(btn);
  };

  const handleEquipmentStatusChange = (_event, option) => {
    if (option) {
      setGeneralEquipmentStatus({ label: option.label, value: option.value });
    }
  };

  const handleMainCommentChange = (event) => {
    setGeneralEquimentComment(event.target.value);
  };

  const handleSaveMainStatus = () => {
    setStatusEditMode(false);
    updateEquipmentGeneralStatusDetails([
      {
        assetNumberPhk,
        statusPhk: generalEquipmentStatus.value,
        comment: generalEquipmentComment,
      },
    ]).then(fetchEquipmentStatusDetails);
  };

  const handleCancelMainStatus = () => {
    setGeneralEquipmentStatus(initialEquipmentStatus);
    setGeneralEquimentComment(initialEquipmentComment);
    setStatusEditMode(false);
  };

  return (
    <div>
      <Button
        size="small"
        startIcon={<ArrowBackIcon />}
        onClick={() => navigate("/work-with-data")}
      >
        Back
      </Button>
      <div className="flex mt-8 justify-between items-center font-fira text-xl not-italic font-bold leading-6">
        <div>{id}</div>
        <div className="flex flex-col items-end">
          {isEquipmentStatusLoading ? null : (
            <Typography className="mb-2">
              {assignedTo
                ? `Assigned for Enrichment to: ${assignedTo}`
                : "Equipment tag is not assigned"}
            </Typography>
          )}
          <Paper className="flex p-2 gap-2" elevation={0} variant="outlined">
            <Autocomplete
              size="small"
              disablePortal
              options={equipmentStatuses}
              value={generalEquipmentStatus.label || ""}
              renderInput={(params) => (
                <TextField {...params} label="Equipment status" />
              )}
              onChange={(event, option) =>
                handleEquipmentStatusChange(event, option)
              }
              isOptionEqualToValue={(option, value) => option.id === value.id}
              sx={{ width: 400 }}
              disableClearable
              disabled={!statusEditMode || isEquipmentStatusLoading}
            />
            <TextField
              className="w-full"
              size="small"
              label="Comments"
              variant="outlined"
              value={generalEquipmentComment}
              disabled={!statusEditMode || isEquipmentStatusLoading}
              onChange={handleMainCommentChange}
              multiline
            />
            {isUserActionEnabled && (
              <div className="flex items-center gap-4">
                {statusEditMode ? (
                  <>
                    <IconButton
                      color="error"
                      onClick={handleCancelMainStatus}
                      data-testid="cancel-btn"
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                      color="success"
                      onClick={handleSaveMainStatus}
                      data-testid="save-btn"
                    >
                      <CheckIcon fontSize="small" />
                    </IconButton>
                  </>
                ) : (
                  <IconButton
                    data-testid="edit-btn"
                    color="primary"
                    onClick={() => setStatusEditMode(true)}
                    disabled={isEquipmentStatusLoading}
                  >
                    <EditIcon fontSize="small" />
                  </IconButton>
                )}
              </div>
            )}
          </Paper>
        </div>
      </div>
      <div className="mt-6">
        <ButtonGroup variant="outlined" aria-label="Equipment details section">
          <Button
            sx={{
              backgroundColor: activeButton === "generalJDE" ? "#0066B214" : "",
            }}
            onClick={() => handleClick("generalJDE")}
            data-testid="generalJDE-btn"
          >
            General JDE
          </Button>
          {/* <Button
            sx={{
              backgroundColor: activeButton === "generalMDS" ? "#0066B214" : "",
            }}
            onClick={() => handleClick("generalMDS")}
            data-testid="generalMDS-btn"
          >
            General MDS
          </Button> */}
          {/* <Button
            sx={{
              backgroundColor:
                activeButton === "class-specific-mds" ? "#0066B214" : "",
            }}
            onClick={() => handleClick("class-specific-mds")}
            data-testid="classSpecificMDS-btn"
          >
            Class-specific MDS
          </Button> */}
          <Button
            sx={{
              backgroundColor: activeButton === "documents" ? "#0066B214" : "",
            }}
            onClick={() => handleClick("documents")}
            data-testid="documents-btn"
          >
            Documents
          </Button>
          <Button
            sx={{
              backgroundColor:
                activeButton === "walkdown-pictures" ? "#0066B214" : "",
            }}
            onClick={() => handleClick("walkdown-pictures")}
            data-testid="walkdown-pictures-btn"
          >
            Walkdown pictures
          </Button>
        </ButtonGroup>
      </div>
      <div className="mt-6">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {activeButton === "generalJDE" && (
            <GeneralJDECharacteristics
              loading={isGeneralJDECharsLoading}
              rows={generalJDEChars}
              costCenterOptions={costCenterOptions}
              equipmentStatuses={equipmentStatuses}
            />
          )}
          {/* {activeButton === "generalMDS" && (
            <GeneralMDSCharacteristics
              loading={isGeneralMDSCharsLoading}
              rows={generalMDSChars}
              costCenterOptions={costCenterOptions}
              equipmentStatuses={equipmentStatuses}
            />
          )} */}
          {/* {activeButton === "class-specific-mds" && (
            <ClassSpecificMDSCharacteristics
              loading={isSpecificMDSCharsLoading}
              rows={specificMDSChars}
              costCenterOptions={costCenterOptions}
              equipmentStatuses={equipmentStatuses}
            />
          )} */}
        </LocalizationProvider>
        {activeButton === "documents" && <EquipmentDocuments />}
        {activeButton === "walkdown-pictures" && (
          <EquipmentWalkdownPictures
            images={imagesInfo?.images || []}
            user={imagesInfo?.user}
            loading={isImagesLoading}
          />
        )}
      </div>
    </div>
  );
};
